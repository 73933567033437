.GlobalTradeStatsBg {
    font-family: "Roboto", sans-serif;
    width: 100%;
    height: auto;
    padding: 60px;
    background-color: #F9F9F9;
}

.EnrchglobData {
    font-family: "Roboto", sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 85%;
    margin: 0 auto;
    gap: 4rem;
    padding: 40px 0 20px;
}

.hrLines {
    height: 3px;
    background: #AEAEAE;
    width: 22%;
    opacity: 1;
    border: 2px solid #AEAEAE;
}

.EnrchglobData h2 {
    font-size: 40px;
    font-weight: 700;
    color: #000;
}

.EnrichDataGlobaltr {
    background: #F9F9F9;
    margin: 20px;
}

.lsitofTradedata {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.lsitofTradedata li {
    display: flex;
    align-items: center;
    width: 25%;
    justify-content: center;
    gap: 1.5rem;
    padding: 35px 20px;
    border-right: 0.1rem solid #3884C7;
}

.lsitofTradedata li:last-child {
    border-right: none;
}

.listofTrade h3 {
    font-size: 52px;
    color: #3884C7;
    font-weight: 900;
    margin-bottom: 0;
}

.listofTrade h4 {
    font-size: 24px;
    font-weight: 500;
    color: #000;
    margin-bottom: 0;
}

.listofTrade p {
    font-size: 14px;
    color: #6E6E6E;
}

.GleoHeainfCover {
    font-family: "Roboto", sans-serif;
    text-align: center;
    padding: 20px 0;
    width: 80%;
    margin: 0 auto;
}

.GleoHeainfCover h2 {
    color: #565656;
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 15px;
}

.GleoHeainfCover p {
    color: #6d6b6b;
    font-size: 22px;
    font-weight: 400;
}

@media screen and (max-width: 768px) {
    .EnrichDataGlobaltr {
        width: 100%;
        margin: 0;
    }

    .lsitofTradedata {
        width: 100%;
    }

    .lsitofTradedata li {
        width: 50%;
        gap: 0.8rem;
        padding: 25px 6px;
        justify-content: flex-start;
    }

    .lsitofTradedata li:nth-child(2) {
        border-right: 0;
    }

    .listofTrade img {
        width: 42px;
        height: 42px;
    }

    .listofTrade h3 {
        font-size: 42px;
    }

    .listofTrade h4 {
        font-size: 20px;
    }

    .GlobalTradeStatsBg {
        padding: 60px 0;
    }

    .GleoHeainfCover {
        width: 100%;
    }
}