.HelpCenterTabsAreaBg {
    font-family: "Roboto", sans-serif;
    width: 100%;
    padding: 40px 0;
    height: auto;
    background-color: #ffffff;
}

.areyouNew {
    font-size: 45px;
    color: #000000;
    font-weight: 600;
    line-height: 150%;
    width: 100%;
    display: block;
    margin: 20px 0;
}

.navItemClsmdn {
    /* margin-bottom: 20px; */
    font-family: "Roboto", sans-serif;
    background-color: #F2F2F2 !important;
    color: #161616 !important;
}

.navItemLlink {
    background: #F2F2F2;
    font-size: 25px;
    font-weight: 500;
    color: #161616;
    padding: 20px 0px;
}

.navItemLlink.active {
    background-color: #000 !important;
    background: #000 !important;
}

.navHeadnLetside {
    border-right: 1px solid #9D9D9D;
    border-radius: 2px;
    padding: 40px 30px 30px;
}

.tabXConterares {
    border-radius: 6px;
    padding: 0px 0px 35px;
}

.loginInVector {
    background: #d3d3d352;
    padding: 15px;
    width: 100%;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
}

.lingNanchTag {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 25px;
    font-weight: 500;
    color: #000000;
}

.lingNanchTag:hover {
    color: #000;
}

.left-tabs-example-tab-first {
    color: #ffffff !important;
}

.tabPaneCenter {
    font-family: "Roboto", sans-serif;
}

.salesQuery {
    color: #3884C7;
    font-size: 25px;
    line-height: 150%;
    font-weight: 500;
    margin-bottom: 20px;
}

.genralsQuery {
    color: #3884C7;
    font-size: 25px;
    line-height: 150%;
    font-weight: 500;
    margin-bottom: 0px;
}

.cunsQuery h4 img {
    height: 31px;
    margin-right: 5px;
}

.cunsQuery {
    margin-bottom: 35px;
    width: 100%;
    display: block;
}

.cunsQuery h4 {
    color: #525252;
    font-size: 20px;
    line-height: 138%;
    font-weight: 400;
    background-color: #F6F6F6;
    display: inline-block;
    padding: 6px 8px;
    cursor: pointer;
}

.tabpNecenflex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: center;
    width: 100%;
    margin: 40px 0;
}

.EmailImgeicn {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 31%;
    height: auto;
    background: #fff;
    border: 1px solid #E5E5E5;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.heheloGet {
    margin-bottom: 0;
    background-color: #16AB79;
    display: flex;
    align-items: center;
    padding: 10px 22px;
    border-radius: 5px;
    color: #fff;
}

.heheloGet a {
    color: #fff;
    cursor: pointer;
}

.EmailImgeicn p {
    width: 100%;
    display: block;
    color: #525252;
    font-size: 14px;
    line-height: 138%;
    margin: 15px 0;
}

.EmailImgeicn a span {
    font-size: 22px;
    line-height: 150%;
    color: #1B1B1B;
    font-weight: 600;
    padding-left: 20px;
    padding-top: 5px;
    vertical-align: middle;
}

.EmailImgeicn a {
    text-decoration: none;
    text-align: left;
    width: 100%;
}

.EmailImgeicn img {
    height: 48px;
}

.EmailImgTiketnFaq {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
    height: auto;
}

.EmailImgTiketn h3 {
    font-size: 20px;
    color: #000;
    font-weight: 500;
    margin-bottom: 25px;
}

.EmailImgTiketn img {
    margin-bottom: 25px;
}

.CreatndLongbtn {
    display: flex;
    gap: 3rem;
}

.longTicketBtn {
    background: #16AB79;
    border-radius: 7px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    border-color: #16AB79;
}

.longTicketBtn:hover {
    background: #03734e;
}

.formNameDetails {
    font-size: 18px;
    line-height: 130%;
    color: #000000;
    font-weight: 400;
    margin-top: 10px;
}

.formAareasboxx {
    border: 1px solid #9a9a9a54;
    border-radius: 6px;
    padding: 30px 40px;
    width: 100%;
}

.submitFormbtn {
    background: #0086CA;
    border-radius: 6px;
    font-size: 20px;
    line-height: 150%;
    color: #ffffff;
    font-weight: 400;
    padding: 10px 25px;
}

/* 
.formNameDetails {
    min-height: 49px;
    height: 49px;
} */

.modalLarge .modal-dialog {
    max-width: 750px !important;
}

@media screen and (max-width: 768px) {
    .tabXConterares {
        margin-top: 0px;
        padding: 0px 10px 25px;
    }

    .navHeadnLetside {
        border-right: 0;
        padding: 0;
    }

    .EmailImgeicn {
        width: 100%;
        margin-bottom: 30px;
        overflow: hidden;
    }

    .formAareasboxx {
        padding: 30px 20px;
    }

    .navItemLlink {
        font-size: 22px;
    }
}

@media screen and (min-width: 769px) and (max-width: 995px) {
    .navHeadnLetside {
        padding: 0;
    }
}