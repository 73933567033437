.TradedataMapOnline {
    width: 100%;
    height: auto;
    background-color: #fff;
    font-family: "Roboto", sans-serif;
    padding: 80px 0;
}

.WrlLrgTxt h2 {
    width: 64%;
    margin: 0 auto;
    font-size: 40px;
    font-weight: 600;
    text-align: left;
    line-height: 55px;
    color: #000;
    padding-top: 25px;
}

.WrlLrgTxt h2 span {
    color: #66B167;
}

.WrlLrgTxt h2 img {
    margin-left: 15px;
}

.WrlLrgBox {
    background-color: #F9F9F9;
    padding: 55px 80px;
    text-align: left;
}

.WrlLrgBox p {
    color: #848484;
    font-size: 16px;
    line-height: 28px;
}

.continentTabs {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    margin-top: 50px;
    background: #FBFBFB;
    padding: 20px;
    border-radius: 16px;
    font-family: "Roboto", sans-serif;
    margin-bottom: 50px;
}

.tabsCntnt {
    color: #8E8E8E;
    font-size: 18px;
    font-weight: 500;
    padding: 8px 45px;
    border-radius: 26px !important;
}

.mapImg img {
    /* width: 100%; */
    height: 332px;
}

.UListCntnt {
    list-style: circle;
    margin-top: 25px;
    display: inline-block;
    width: 100%;
}

.CstmDatat {
    color: #008000;
}

.StstDtDatat {
    color: #0d6efd;
}

.CstmDatat a {
    color: #008000;
}

.StstDtDatat a {
    color: #0d6efd;
}

.UListCntnt li {
    width: 33%;
    float: left;
    text-align: left;
    list-style: disc;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
}

.UListCntnt li a {
    text-decoration: none;
    /* color: #6C6C6C; */
    font-size: 14px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
}

.CntntTextBr {
    color: #171A31;
    font-size: 24px;
    text-align: left;
    text-decoration: underline;
    padding-bottom: 6px;
}

.CntntTextBr h3 {
    color: #171A31;
    font-size: 24px;
    text-align: left;
    text-decoration: underline;
}

.DtaYpe {
    display: flex;
    width: 100%;
    gap: 4rem;
    padding-top: 30px;
}

.CstmDt {
    /* list-style: url(/public/custms.png); */
    color: #008000;
    list-style-type: disc;
    font-weight: 500;
}

.StstDt {
    /* list-style: url(/public/stats.png); */
    color: #0d6efd;
    font-weight: 500;
    list-style-type: disc;
}

@media screen and (max-width: 768px) {
    .WrlLrgTxt h2 {
        width: 100%;
        padding-top: 0;
        margin-bottom: 20px;
    }

    .WrlLrgBox {
        padding: 15px;
    }

    .continentTabs {
        width: 100%;
        margin: 40px 0;
        padding: 5px;
    }

    .UListCntnt {
        display: inline-block;
        padding-left: 1em;
    }

    .DtaYpe {
        padding-left: 2em;
    }
    .navClassWidth{
        width: 46%;
    }

}