.nav-link.active {
    color: #000;
    background-color: #fff;
    border-color: #fff;

}

.nav-link:hover {
    color: #000;
    background-color: #fff;
    border-color: #fff;
}

.nav-tabs {
    top: -25px;
    width: 100%;
    position: sticky;
    background-color: #f3f8ff;
}