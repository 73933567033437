.GlobalTradeStatsBg {
    font-family: "Roboto", sans-serif;
    width: 100%;
    height: auto;
}

.EnrchglobData {
    font-family: "Roboto", sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 85%;
    margin: 0 auto;
    gap: 4rem;
    padding: 40px 0 20px;
}

.hrLines {
    height: 3px;
    background: #AEAEAE;
    width: 22%;
    opacity: 1;
    border: 2px solid #AEAEAE;
}

.EnrchglobData h2 {
    font-size: 40px;
    font-weight: 700;
    color: #000;
}

.lsitofTradedata {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.lsitofTradedata li {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: left;
    gap: 1.5rem;
    padding: 15px 10px;

}

.lsitofTradedata li:last-child {
    border-right: none;
}

.listofTrade h3 {
    font-size: 32px;
    color: #66B167;
    font-weight: 600;
    margin-bottom: 0;
}

.listofTrade h4 {
    font-size: 34px;
    font-weight: 500;
    color: #000;
    margin-bottom: 0;
}

.listofTrade p {
    font-size: 16px;
    font-weight: 500;
    color: #737373;
}

@media screen and (max-width: 768px) {
    .EnrichDataGlobaltr {
        width: 100%;
        margin: 0;
    }

    .lsitofTradedata {
        width: 100%;
    }

    .lsitofTradedata li {
        width: 100%;
        gap: 1.8rem;
        padding: 25px 6px;
        justify-content: flex-start;
    }

    .lsitofTradedata li:nth-child(2) {
        border-right: 0;
    }

    .listofTrade img {
        width: 42px;
        height: 42px;
    }

    .listofTrade h3 {
        font-size: 42px;
    }

    .listofTrade h4 {
        font-size: 20px;
    }
}