.CopyrighFrt{
    background-color: #F8F8F8;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-family: "Poppins", sans-serif;
}

.CopyrighForLinst a{
    color: #6F6F6F;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    text-decoration: none;
    padding: 0 12px;
    font-weight: 500;
}
.CopyrighForLinst a:hover{
    color: #000;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    text-decoration: none;
}