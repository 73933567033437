.NeedplanHelpBg {
    font-family: "Roboto", sans-serif;
    width: 100%;
    height: auto;
    padding: 60px 0;
    background-color: #fff;
}

.Chooseplan {
    width: 100%;
    text-align: center;
}

.Chooseplan h2 {
    font-size: 40px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    color: #000;
    text-align: center;
    margin-bottom: 30px;
}

.ChooseplanBoxex {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px 0;
}

.choseneed {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem; */
    text-align: center;
    width: 218px;
    height: 218px;
    padding: 30px 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 6px;
    align-content: center;
}

.choseneed p {
    font-size: 16px;
}

.choseneed img {
    margin-bottom: 25px;
}

.choseneed a {
    font-size: 16px;
    color: #3884C7;
    text-transform: uppercase;
    font-weight: 500;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-decoration: none;
}

@media screen and (max-width: 768px) {
    .ChooseplanBoxex {
        gap: 0.5rem;
        justify-content: flex-start;
    }

    .choseneed {
        width: 48%;
        height: auto;
        padding: 25px 10px;
    }
}