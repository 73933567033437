.DatatypebgOnline {
    font-family: "Roboto", sans-serif;
    width: 100%;
    padding: 80px 0;
    height: auto;
    background-image: url(/public/datypebg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;
}

.GeGlobl {
    font-family: "Roboto", sans-serif;
    text-align: left;
}

.GeGlobl h2 {
    font-size: 40px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 10px;
}

.GeGlobl p {
    font-size: 16px;
    color: #FFFFFF;
    line-height: 28px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
}