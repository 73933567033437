.Cancelpolicypage,
.Refundpolicypage {
    padding: 50px 0;
    width: 100%;
    display: block;
    background-color: #fff;
    border-bottom: 1px solid #ebebeb;
}

.GeheadingPolicy {
    font-family: "Roboto", sans-serif;
}

.GeheadingPolicy h1 {
    color: #000;
    font-size: 30px;
    line-height: 46px;
    margin-top: 25px;
}

.GeheadingPolicy p {
    color: #868686;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 15px;
}

.pvcpol_cnt1 {
    padding: 35px 0 0 0;
    font-family: "Roboto", sans-serif;
}

.pvcpol_cnt1 h2 {
    font-size: 25px;
    font-weight: 600;
    line-height: 47px;
    color: #0c0c0c;
    text-align: left;
}

.pvcpol_cnt1 p {
    font-size: 15px;
    color: #868686;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
    padding-top: 10px;
    margin: 0 0 10px;
}

.pvcpol_cnt1 p b {
    font-weight: 500;
    color: #474343;
}

.firlemtul {
    margin-bottom: 15px;
    width: 100%;
    display: inline-block;
    padding: 0;
    list-style: auto;
}

.firlemtul li {
    color: #868686;
    font-size: 15px;
    line-height: 26px;
    margin-left: 30px;
    margin-bottom: 4px;
    padding-left: 10px;
}

.pvcpol_cnt1 h5 {
    font-weight: 500;
    color: #474343;
    margin-bottom: 0px;
    font-size: 16px;
}

.typesofData {
    padding: 15px 0 0 0;
    font-family: "Roboto", sans-serif;
}

.typesofData h3 {
    font-size: 22px;
    font-weight: 600;
    line-height: 55px;
    color: #0c0c0c;
    margin-bottom: 10px;
}

.personalDataUse {
    font-family: Poppins, sans-serif;
    padding: 0 0 25px 20px;
}

.personalDataUse h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 42px;
    color: #474343;
    margin-bottom: 6px;
}

.personalDataUse p {
    font-size: 15px;
    color: #868686;
    margin-bottom: 10px;
    line-height: 26px;
    letter-spacing: 0px;
    font-weight: 400;
}

.personalDataUse p b {
    font-weight: 500;
    color: #474343;
}

.dataIncluedeof {
    margin-bottom: 15px;
    width: 100%;
    display: inline-block;
    padding: 0;
}

.dataIncluedeof li {
    color: #868686;
    font-size: 15px;
    line-height: 26px;
    margin-left: 30px;
    margin-bottom: 4px;
    border: 0;
    width: 100%;
    list-style: square;
}

.dataIncluedeof li b {
    font-weight: 500;
    color: #474343;
}