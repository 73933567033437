.ExclusiveDataBg {
    font-family: "Roboto", sans-serif;
    width: 100%;
    height: auto;
    padding: 35px 0;
    background-color: #F7F7F7;
}

.ExclCntrdtWh {
    background: #fff;
    padding: 30px;
    width: 35%;
    text-align: right;
    padding-right: 100px;
    float: left;
    clip-path: polygon(0% 0%, 88.8% 0%, 96.1% 53.1%, 96.1% 52.1%, 88.8% 100%, 0% 100.4%, 0% 94%, 0% 15%);
    /* polygon(0% 0%, 88.8% 0%, 96.1% 53.1%, 96.1% 52.1%, 88.8% 100%, 0% 100.4%, 0% 94%, 0% 15%) */
    /* clip-path: polygon(0% 0%, 87.8% 1.2%, 96.1% 52.3%, 96.1% 52.1%, 89.3% 100%, 0% 100.4%, 0% 85%, 0% 15%); */
}

.ExclCntrdtWh h3 {
    font-size: 26px;
    color: #00CA6F;
    font-weight: 600;
    margin-bottom: 3px;

}

.grnBgcolor {
    width: 100%;
    background: #66B167;
    padding: 0;
}

.ExclCntrdtGR {
    background: #66B167;
    padding: 30px;
    width: 100%;
    height: 100%;

}

.ExclCntrdtGR ul {
    padding: 0;
    margin: 0;
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
}

.ExclCntrdtGR ul li {
    list-style: none;
    font-size: 18px;
    color: #fff;
}

.ExclCntrdtGR ul li a {
    color: #fff;
    text-decoration: none;
}

@media screen and (max-width: 768px) {
    .ExclCntrdtWh {
        width: 100%;
        text-align: left;
        padding: 30px 0 30px 30px;
    }

    .ExclCntrdtGR ul {
        gap: 1rem;
        align-items: center;
        justify-content: start;
        text-align: center;
        flex-wrap: wrap;
    }

    .ExclCntrdtGR {
        float: left;
        height: auto;
    }
}