.CareerweMarchOBg {
    font-family: "Roboto", sans-serif;
    width: 100%;
    padding: 80px 0;
    height: 650px;
    background-image: url(/public/clientbg.png);
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
}

.carkeermarch {
    text-align: left;
    width: 45%;
    position: absolute;
    left: 13%;
    right: 0;
    top: 33%;
    border: 0;
    margin: 0 auto;
    height: auto;
    font-family: "Roboto", sans-serif;
}

.carkeermarch h3 {
    font-size: 55px;
    text-transform: uppercase;
    color: #FFFFFF;
    font-weight: 300;
    border-bottom: 4px solid;
    width: 60%;
    padding-bottom: 15px;
}

.carkeermarch h1 {
    font-size: 100px;
    text-transform: uppercase;
    color: #FFFFFF;
    font-weight: 600;
    line-height: 107.1px;
}

@media screen and (max-width: 768px) {
    .CareerweMarchOBg {
        background-position: center top;
        height: auto;
    }

    .carkeermarch {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
    }

    .carkeermarch h3 {
        font-size: 35px;
        width: 75%;
    }

    .carkeermarch h1 {
        font-size: 60px;
        line-height: 67.1px;
    }
}