.CompareButtonBg {
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 60px 0;
}

.notSure {
    text-align: center;
    width: 100%;
    display: block;
}

.notSure p {
    color: #000;
    margin-bottom: 20px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    font-size: 24px;
}

.CompareNtn {
    background-color: #3884C7;
    color: #fff;
    border-radius: 8px;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    padding: 12px 35px;
    border-color: #3884C7;
    text-decoration: none;
}