.headerImageIcon {
    display: inline;
}

.navLinsk {
    color: #000;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    font-size: 0.9rem;
    text-transform: uppercase;
}

.loingBtn {
    background-color: #3884C7;
    color: #fff !important;
    border-radius: 40px;
    padding: 9px 40px !important;
}

.loingBtn:hover {
    background-color: #0e5b9f !important;
    color: #fff !important;
}

.TrlBtn {
    background-color: #478E48;
    color: #fff !important;
    border-radius: 40px;
    padding: 9px 40px !important;
}

.TrlBtn:hover {
    background-color: #1a761c !important;
    color: #fff !important;
}

.navBrnNab {
    gap: 1rem;
}

.navBrndBg {
    background-color: #fff !important;
}

.navdropItemslnk {
    text-transform: capitalize;
    border-bottom: 1px dashed #dcdcdc;
    padding: 0.65rem 1.65rem;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
}

.navdropItemslnk:hover {
    background-color: #0d6efd;
    color: #fff;
}

.svgImgNav {
    margin-right: 13px;
    fill: #212529;
}

.navdropItemslnk:hover .svgImgNav {
    fill: #fff;
}