.LeverageApiPage {
    background-image: linear-gradient(to right, #0b5d9c, #24407d);
    padding: 80px 0;
    width: 100%;
    height: auto;
    font-family: "Roboto", sans-serif;
}

.datWIthLeverg {
    font-family: "Roboto", sans-serif;
    text-align: center;
    color: #fff;
    width: 85%;
    margin: 0 auto;
}

.datWIthLevergYTabs {
    font-family: "Roboto", sans-serif;
    text-align: center;
    color: #fff;
    width: 85%;
    margin: 10px auto;
}

.datWIthLeverg h2 {
    font-size: 46px;
    font-weight: 600;
    margin-bottom: 20px;
}

.datWIthLeverg p {
    font-size: 17px;
    font-weight: 400;
    line-height: 31px;
}

.TbnnLinsItmsk {
    margin: 0;
    width: 100%;
}

.TbnnLinsk {
    margin: 5px 0;
    padding: 10px 10px 10px 10px;
    text-align: left;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
}

.TbnnLinsk.active {
    color: #000 !important;
    background-color: #fff !important;
    border-color: #fff !important;
}

.TbnnLinsk:active {
    color: #000 !important;
    background-color: #fff !important;
    border-color: #fff !important;
}

.TbnSectPane {
    background: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
}

.TbnnLTabPnae {
    height: 100%;
    background: #f9f9f9;
    border-radius: 10px;
    padding: 20px 10px;
    text-align: left;
    font-family: "Roboto", sans-serif;
}

.TbnSectPane h3 {
    font-size: 40px;
    color: #0c5b9b;
    font-weight: 610;
    margin: 0 0 20px;
}

.TbnSectPane p {
    font-size: 16px;
    color: #2d2e2e;
    line-height: 26px;
    margin-bottom: 15px;
}

.dataCodeTxt {
    font-family: "Roboto", sans-serif;
    margin-bottom: 25px;
}

.dataCodeTxt h4 {
    font-size: 25px;
    color: #2d2e2e;
    font-weight: 500;
}

.sectoOfcde {
    background-color: #f3f8ff;
    height: 455px;
    min-height: 455px;
    overflow: hidden;
    overflow-x: scroll;
    overflow-y: scroll;
    padding: 30px;
    text-align: left;
    width: 100%;
}

pre {
    color: #6e6969;
    overflow: hidden;
}

.UlislitNavRveit {
    margin-top: 35px;
}


.scheDemoBtn {
    padding: 8px 20px;
    margin-right: 15px;
    background: #0a5e9e;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    border: none;
    border-top: 3px solid #0a5e9e;
    border-bottom: 3px solid #0a5e9e;
    border-radius: 0;
    overflow: hidden;
    position: relative;
    letter-spacing: 0.56px;
    transition: all 0.3s ease 0s;
}

.scheDemoBtn::before {
    content: "";
    border-top: 15px solid #0a5e9e;
    border-right: 15px solid transparent;
    border-bottom: 15px solid transparent;
    position: absolute;
    top: 0;
    left: -50%;
    transition: all 0.3s ease 0s;
}

.scheDemoBtn::after {
    content: "";
    border-bottom: 15px solid #0a5e9e;
    border-left: 15px solid transparent;
    border-top: 15px solid transparent;
    position: absolute;
    bottom: 0;
    right: -50%;
    transition: all 0.3s ease 0s;
}

.scheDemoBtn:hover {
    border: none;
    border-top: 3px solid #0a5e9e;
    border-bottom: 3px solid #0a5e9e;
    background: #fff;
    color: #0a5e9e;
}

.scheDemoBtn:hover::before {
    left: 0;
}

.scheDemoBtn:hover::after {
    right: 0;
}

.LogonDemoBtn {
    color: #fff;
    background-color: #444;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 8px 20px;
    border-radius: 0;
    border: 3px solid #444;
    position: relative;
    letter-spacing: 0.56px;
    z-index: 1;
    transition: all .3s ease-in-out;
}

.LogonDemoBtn:focus,
.LogonDemoBtn:hover {
    color: #0a5e9e;
    background-color: transparent;
    border-radius: 0 15px;
    border-color: #0a5e9e;
}

.LogonDemoBtn:before {
    content: "";
    background: linear-gradient(to right bottom, #0a5e9e 50%, transparent 51%);
    width: 50%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease;
}

.LogonDemoBtn:focus:before,
.LogonDemoBtn:hover:before {
    width: 25px;
    height: 25px;
    opacity: 1;
    left: -2px;
    top: -2px;
}



.FomrDentnormCrer {
    width: 100%;
    padding: 20px;
    background: #fff;
    border-radius: 6px;
    font-family: "Roboto", sans-serif;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}


.formLaelCar {
    font-size: 16px;
    color: #171a31bb;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}

.formContrCaresd {
    border-radius: 2px;
    color: #A19D9D;
    outline: none;
    box-shadow: none;
    border-color: #A19D9D;
}

.formContrCaresd:focus {
    outline: none;
    box-shadow: none;
    border-color: #A19D9D;
}

.formLaelCarresm {
    font-size: 16px;
    color: #292929;
    font-family: "Roboto", sans-serif;
}

.modalHeader {
    background-image: linear-gradient(to right, #1d60dc, #2c6ee8);
    color: #fff;
    padding: 14px 20px;
}

.subtmBtinFrom {
    color: #fff;
    background-color: #333;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: capitalize;
    padding: 8px 22px 9px;
    border: none;
    border-radius: 0;
    position: relative;
    z-index: 1;
    transition: all 0.4s ease 0s;
}

.subtmBtinFrom:focus {
    color: #fff;
}

.subtmBtinFrom:hover {
    color: #fff;
    background: #333;
    box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.5);
}

.subtmBtinFrom:before,
.subtmBtinFrom:after {
    content: '';
    background-color: #154bbf;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: scaleX(0);
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    transition: all 0.3s ease-in 0s;
}

.subtmBtinFrom:after {
    background-color: #154bbf;
    border-radius: 0;
    transform: scaleX(1);
}

.subtmBtinFrom:hover:before {
    border-radius: 0;
    opacity: 1;
    transform: scaleX(1);
}

.subtmBtinFrom:hover:after {
    border-radius: 50%;
    opacity: 1;
    transform: scaleX(0);
}


@media only screen and (max-width: 767px) {

    .datWIthLeverg,
    .datWIthLevergYTabs {
        width: 100%;
    }

    .scheDemoBtn {
        margin-bottom: 15px;
    }
}