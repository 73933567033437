.nav-mobile {
  justify-content: flex-start !important;
  gap: 1rem;
}

.nav-link.active {
  color: #fff !important;
  background: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1)) !important;
  border-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1)) !important;
}

/* .nav-link:hover{
  color: #fff!important;
  background: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1))!important;
  border-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1))!important;
} */

.slider {
  width: 100%;
  height: 100px;
  /* Adjust the height as needed */
  overflow: hidden;
  position: relative;
  object-fit: scale-down;
  background: none;
  mix-blend-mode: darken;
}

.slide-track {
  display: flex;
  width: calc(250px * 16);
  /* Width = (image width + margin) * number of images */
  animation: scroll 25s linear infinite;
}

.tab-content>.tab-pane {
  margin-top: 35px;
}

.slide-track img {
  width: 250px;
  /* Adjust the width as needed */
  margin: 0 10px;
  height: 100px;
  /* Adjust the height as needed */
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 8));
    /* Translate by the width of half the images */
  }
}