.HereToHelpPage {
    font-family: "Roboto", sans-serif;
    width: 100%;
    height: auto;
    padding: 60px;
    background-color: #ffffff;
}

.ForHereTole {
    margin: 30px 0;
    width: 90%;
    padding: 40px 40px 5px;
    background: #fff;
    border-radius: 6px;
    font-family: "Roboto", sans-serif;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.aformareasent {
    padding: 20px 0;
}

.formLaelCar {
    font-size: 16px;
    color: #171a31bb;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}

.formContrCaresd {
    border-radius: 2px;
    color: #A19D9D;
    outline: none;
    box-shadow: none;
    border-color: #A19D9D;
}

.formContrCaresd:focus {
    outline: none;
    box-shadow: none;
    border-color: #A19D9D;
}

.ForHereTole h3 {
    font-size: 55px;
    color: #224480;
    font-weight: 600;
    margin: 0 0 35px;
    font-family: "Roboto", sans-serif;
}

.formLaelCarresm {
    font-size: 16px;
    color: #292929;
    font-family: "Roboto", sans-serif;
}

.subtmBtinFrom {
    padding: 10px 20px;
    margin-right: 15px;
    background: #0a5e9e;
    font-family: "Roboto", sans-serif;
    font-size: 17px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    border: none;
    border-top: 3px solid #0a5e9e;
    border-bottom: 3px solid #0a5e9e;
    border-radius: 0;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease 0s;
}

.subtmBtinFrom::before {
    content: "";
    border-top: 15px solid #0a5e9e;
    border-right: 15px solid transparent;
    border-bottom: 15px solid transparent;
    position: absolute;
    top: 0;
    left: -50%;
    transition: all 0.3s ease 0s;
}

.subtmBtinFrom::after {
    content: "";
    border-bottom: 15px solid #0a5e9e;
    border-left: 15px solid transparent;
    border-top: 15px solid transparent;
    position: absolute;
    bottom: 0;
    right: -50%;
    transition: all 0.3s ease 0s;
}

.subtmBtinFrom:hover {
    border: none;
    border-top: 3px solid #0a5e9e;
    border-bottom: 3px solid #0a5e9e;
    background: #fff;
    color: #0a5e9e;
}

.subtmBtinFrom:hover::before {
    left: 0;
}

.subtmBtinFrom:hover::after {
    right: 0;
}

@media screen and (max-width: 768px) {
    .HereToHelpPage {
        padding: 60px 0;
    }

    .ForHereTole {
        width: 100%;
        padding: 10px 10px 5px;
        box-shadow: none;
    }

    .ForHereTole h3 {
        margin: 0 0 15px;
        font-size: 44px;
    }
}