.TestiCommentBg {
    width: 100%;
    font-family: "Roboto", sans-serif;
    height: auto;
    padding: 80px 0 200px;
    background-color: #F2F2F2;
}

.TesmTheding {
    font-family: "Roboto", sans-serif;
    text-align: center;
    width: 50%;
    margin: 20px auto;
    padding: 30px;
}

.TesmTheding h2 {
    font-size: 55px;
    color: #171A31;
    font-weight: 700;
    margin-bottom: 25px;
}

.hrlintemondil {
    border: 2px solid #202020;
    width: 20%;
    margin: 10px auto;
    opacity: 1;
}

.Writerjack {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
}

.TestiRechText {
    background: #FCFCFC;
    position: relative;
    padding: 40px;
    transition: width 1s, height 1s;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    width: 432px;
    height: 375px;
    min-height: 375px;
    font-family: "Roboto", sans-serif;
}

.TestiRechText:hover {
    background-color: #3884C7;
    width: 450px;
    height: 280px;
}

.TestiRechText:hover p {
    color: #fff;
}

.TestiRechText img {
    margin-bottom: 20px;
}

.TestiRechText p {
    font-size: 16px;
    line-height: 28px;
    color: #848484;
    margin-bottom: 30px;
    padding: 0;
}

.jaWriteImg {
    position: absolute;
    left: 0;
    right: 0;
    font-family: "Roboto", sans-serif;
    bottom: -32%;
}

.jaWriteImg h5 {
    font-size: 16px;
    color: #4F4F4F;
}

.jaWriteImg h6 {
    color: #848484;
    font-size: 16px;
}

@media screen and (max-width: 768px) {
    .TestiCommentBg {
        padding: 40px 0;
    }

    .TesmTheding {
        width: 100%;
        margin: 0;
    }

    .Writerjack {
        flex-wrap: wrap;
    }

    .TestiRechText {
        width: 95%;
        height: auto;
        padding: 30px;
        margin-bottom: 10em;
    }

    .TestiRechText:hover {
        width: 100%;
    }
}