.onlinePlatform {
    width: 100%;
    height: 100%;
    background-color: #F9F9F9;
    padding: 60px 0;
}

.tradeBg img {
    width: 100%;
}

.tradeText {
    text-align: left;
    margin-top: 30px;
    font-family: "Roboto", sans-serif;
}

.howYOur {
    color: #171A31;
    font-size: 55px;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    margin-bottom: 15px;
    padding-top: 2rem;
}

.actnIsht {
    color: #0d6efd;
    font-size: 20px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    margin-bottom: 25px;
}

.tradeText p {
    color: #171a31b0;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
}

.formFlext {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 10px 0;
    gap: 1rem;
}

.TrlBtn {
    background-color: #3884C7;
    color: #fff;
    border-radius: 40px;
    padding: 11px 40px !important;
    text-decoration: none;
    vertical-align: -webkit-baseline-middle;
    display: inline-block;
    text-align: center;
}

.frokDemo {
    border-color: #CACACA;
    border-radius: 100px;
    padding: 10px 25px;
    width: 300px;
    margin-left: 15px;
}

.captImg {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
}

.linanchdem {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

/* 
.frokDemo {
    width: 64%;
} */

@media screen and (max-width: 768px) {
    .onlinePlatform {
        padding: 40px 0;
    }

    .tradeText {
        margin-left: 0px;
        margin-top: 10px;
    }

    .tradeText h1 {
        font-size: 45px;
    }

    .tradeText h3 {
        padding: 6px 20px;
    }

    .linanchdem {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    .captImg {
        justify-content: space-around;
    }

    .captImg img {
        width: 26%;
    }

    .frokDemo {
        width: 100%;
        margin-left: 0;
    }

    .TrlBtn {
        font-size: 14px;
    }

    .howYOur {
        padding-top: 0;
        font-size: 48px;
    }

    .formFlext {
        flex-direction: column;
    }

}