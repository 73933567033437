.oveClntsSection {
    width: 100%;
    font-family: "Roboto", sans-serif;
    height: auto;
}

.oveClnts {
    background-image: linear-gradient(to right, #283E7A, #0C5E9D);
    padding: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: "Roboto", sans-serif;
}

.oveClntsImng {
    background: #D9D9D9;
    padding: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.selitxtImngf img {
    width: 20%;
    object-fit: scale-down;
    background: none;
    mix-blend-mode: darken;
}

.selitxt h2 {
    font-size: 44px;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 20px;
    font-family: "Roboto", sans-serif;
    line-height: 55px;
}

.selitxt p {
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    margin-bottom: 0;
    font-family: "Roboto", sans-serif;
    padding: 0;
}

.selitxtImngf {
    width: 90%;
    margin: 0 auto;
}

.selitxt {
    text-align: center;
}

.clientListImngs {
    list-style: none;
    display: inline-flex;
    gap: 1rem;
    margin-bottom: 0;
}

.oveClnts {
    margin-top: 35px;
}

@media screen and (max-width: 768px) {
    .oveClnts {
        width: 100%;
        padding: 60px 25px;
    }

    .oveClntsImng {
        width: 100%;
        padding: 60px 10px;
    }

    .selitxt h2 {
        font-size: 38px;
    }
}