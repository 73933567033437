.GetGlobalTradeOnline {
    font-family: "Roboto", sans-serif;
    width: 100%;
    padding: 80px 0;
    height: 310px;
    /* background-image: url(/public/get-global.png); */
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: block;
}

.GeGlobl{
    font-family: "Roboto", sans-serif;
    text-align: left;
    position: absolute;
    left: 7%;
    top: 13%;
    width: 90%;
}

.GeGlobl h2 {
    font-size: 38px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.GeGlobl h3 {
    text-transform: uppercase;
    font-size: 30px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 25px;
}

.scheDemoBtn {
    background: #66B167;
    padding: 10px 30px;
    border-radius: 40px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin-right: 20px;
}

.LogonDemoBtn {
    background: #3884C7;
    padding: 10px 30px;
    border-radius: 40px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
}

@media screen and (max-width: 768px) {
    .GetGlobalTradeOnline {
        background-position: bottom center;
        background-image: url(/public/get-global.png);
        height: auto;
    }

    .tagIDe {
        display: none;
    }
    .scheDemoBtn, .LogonDemoBtn{
        width: 100%;
        display: block;
    }
    .scheDemoBtn{
        margin-bottom: 15px;
    }
    .GeGlobl {
        position: relative;
        left: 0;
        top: 0;
    }
}