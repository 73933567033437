.TestiClientImgBg {
  background-image: url(/public/testimonialbg.png);
  background-repeat: no-repeat;
  padding: 80px 0;
  width: 100%;
  height: 560px;
}

@media screen and (max-width: 768px) {
  .TestiClientImgBg {
    padding: 30px 0;
    height: auto;
  }
}