.AllSolutionsListBG {
    background-color: #FCFCFC;
    width: 100%;
    height: auto;
    padding: 80px 0;
    font-family: "Roboto", sans-serif;
    margin-bottom: 40px;
}

.tabsOfSolutions {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 13px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.Solutheading {
    padding: 40px 0 0;
}

.ForSoltinng {
    padding: 30px;
}

.elementofSlt {
    position: relative;
    height: 480px;
    overflow: scroll;
    overflow-x: hidden;
    padding: 20px;
}

.elementofSlt::-webkit-scrollbar {
    display: none;
}


.linActivetb .active {
    color: #000;
    font-weight: 600;
}

.active {
    color: #3884C7 !important;
    font-weight: 600;
    text-decoration: none;
}

.linActivetb a {
    /* color: #989898; */
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}

a.active {
    color: #3884C7 !important;
}

a:active {
    color: #3884C7 !important;
    text-decoration: none;
}
a:focus{
    text-decoration: none;
}


.imprSecoDesti {
    font-family: "Roboto", sans-serif;
}

.imprSecoDesti h5 {
    color: #3884C7;
    font-size: 24px;
    font-weight: 500;
}

.imprSecoDesti h3 {
    font-size: 40px;
    color: #000;
    font-weight: 600;
    margin-bottom: 35px;
}

.imprSecoDesti p {
    color: #848484;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
}

.ulilistSolutin {
    padding-left: 0;
    margin-top: 30px;
}

.ulilistSolutin li {
    background-image: url(/public/lisright.png);
    background-repeat: no-repeat;
    list-style: none;
    padding-left: 54px;
    background-position: 0px 3px;
    color: #848484;
    font-size: 16px;
    margin-bottom: 25px;
    line-height: 28px;
}

.imeImageDash img {
    width: 100%;
}