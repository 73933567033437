.FaQuestionsBg {
    background-color: #ffffff;
    width: 100%;
    height: auto;
    padding: 30px;
    font-family: "Roboto", sans-serif;
    margin: 40px 0 60px;
}

.FaQuestionsText {
    font-family: "Roboto", sans-serif;
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 35px;
    justify-content: space-between;
}

.FaQuestionsText h2 {
    font-size: 40px;
    color: #000000;
    font-weight: 600;
}

.FaQuestionsText a {
    font-size: 20px;
    color: #000000;
    font-weight: 500;
    text-decoration: none;
}

.CllaseCard {
    border: 0;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    margin-bottom: 10px;
}

.btnPromeColaspe {
    font-size: 18px;
    color: #66B167;
    background: transparent;
    border: 0;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    width: 100%;
    text-align: left;
}

.btnPromeColaspe:hover {
    color: #66B167;
    background-color: transparent;
}

.btnPromeColaspe:active {
    color: #66B167 !important;
    background-color: transparent !important;
}

.btnPromeColaspe svg {
    float: right;
}

.bodytextColssp {
    font-size: 14px;
    color: #6E6E6E;
    font-family: "Roboto", sans-serif;
    line-height: 26px;
    padding: 0.75rem;
}

.bodytextColssp ul {
    list-style: disc;
}

.bodytextColssp ul li {
    margin: 10px 0;
}

@media screen and (max-width: 768px) {
    
    .FaQuestionsText h2 {
        font-size: 36px;
        text-align: left;
    }

    .FaQuestionsText {
        display: block;
    }

    .btnshowall {
        float: right;
        margin-top: 18px;
        margin-bottom: 18px;
    }

    .FaQuestionsBg {
        padding: 30px 0;
    }
}