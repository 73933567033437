.OurCulturebG {
    font-family: "Roboto", sans-serif;
    width: 100%;
    padding: 50px 0;
    background-color: #fff;
}

.Seekpeek {
    font-family: "Roboto", sans-serif;
    text-align: center;
    width: 100%;
    padding: 20px 0;
}

.mb2{
    margin-bottom: 30px;
}
.Seekpeek h2 {
    font-size: 40px;
    font-weight: 600;
    color: #000;
}

.OurCultureImg {
    font-family: "Roboto", sans-serif;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    padding: 37px 50px;
    height: 100%;
    width: 88%;
    margin: 0 auto;
    border-radius: 10px;
    background: #FCFCFC;
    margin-bottom: 35px;
}

.OurCultureImg img {
    margin-bottom: 20px;
}

.OurCultureImg h4 {
    font-size: 24px;
    font-weight: 500;
    color: #000;
    margin-bottom: 15px;
}

.OurCultureImg p {
    font-size: 16px;
    color: #848484;
    font-weight: 400;
    line-height: 179%;
}

@media screen and (max-width: 768px) {
    .OurCulturebG {
        padding-top: 0;
    }

    .Seekpeek {
        padding: 0;
    }

    .OurCultureImg {
        width: 100%;
    }
}