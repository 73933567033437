.DataTypeManagerBg {
    font-family: "Roboto", sans-serif;
    width: 100%;
    padding: 80px 0;
    height: auto;
    background-color: #fff;
}

.pltrtradeText {
    font-family: "Roboto", sans-serif;
    width: 100%;
    text-align: left;
    padding: 20px 0;
}

.pltrtradeText h2 {
    font-size: 40px;
    font-weight: 600;
    color: #000;
    margin-bottom: 13px;
}

.tabofMatrial {
    color: #4F4F4F !important;
    font-weight: 500 !important;
    font-family: "Roboto", sans-serif;
    font-size: 16px !important;
    text-transform: math-auto !important;
}

.comprHendTbs {
    padding: 20px 0;
}

.inetleInstigh {
    font-family: "Roboto", sans-serif;
    margin-bottom: 25px;
}

.borboxtabs {
    padding: 36px;
    border: 1px solid #BDBDBD;
    width: 100%;
}

.inetleInstigh a {
    font-size: 16px;
    font-weight: 500;
    color: #3884C7;
    margin-bottom: 15px;
    text-decoration: none;
    margin-top: 15px;
    display: block;
}

.inetleInstigh h3 {
    font-size: 24px;
    font-weight: 500;
    color: #000;
    margin-bottom: 15px;
}

.inetleInstigh p {
    font-size: 14px;
    font-weight: 400;
    color: #6E6E6E;
    line-height: 179%;
    text-align: left;
}

/* .inimgigh img {
    width: 100%;
} */

.DnownSample {
    background-color: #3884C7;
    color: #fff;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    border-radius: 3px;
    margin-right: 25px;
    font-weight: 400;
    padding: 9px 30px;
    margin-left: 10px;
}

.dataAvailSamle {
    width: 100%;
    margin: 20px 0;
}

@media screen and (max-width: 768px) {
    .DnownSample {
        margin-bottom: 15px;
    }
}