body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer tailwind-base, primereact, tailwind-utilities;

/* IMPORTANT: In "styled" mode you must add the PrimeReact Theme here. Do NOT include in "unstyled" mode */
@import 'primereact/resources/themes/lara-light-blue/theme.css' layer(primereact);

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

a{
  text-decoration: none;
}
a:hover{
  text-decoration: none!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
img, svg{
  display: inline;
}

.form-switch .form-check-input {
  height: 2.2em !important;
  width: 5em !important;
}

.Acroheader .accordion-button:not(.collapsed) {
  color: #2D2D2D;
  background-color: transparent;
  box-shadow: none;
}

.Acroheader .accordion-button:focus {
  box-shadow: none;
  outline: 0;
}

.accordion-button:not(.collapsed) {
  color: #2D2D2D;
  background-color: transparent;
  box-shadow: none;
}

.accordion-button:focus {
  box-shadow: none;
  outline: 0;
}

.slick-prev:before,
.slick-next:before {
  color: #000 !important;
}

.slick-prev,
.slick-next {
  top: 105% !important;
}

.slick-next {
  right: 80% !important;
}

.slick-prev {
  left: 15% !important;
}


@media screen and (max-width: 768px) {
  .slick-next {
    right: 65% !important;
  }

  .slick-prev {
    left: 20% !important;
  }
}

